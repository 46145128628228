import { FC, useMemo } from 'react';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { Grid } from '../../../../components/Grid';
import { Diff, FileDiff } from '../../../../components/Diff';
import { useResult } from '../../ResultProvider';
import { useYear } from 'hooks/useYear';
import { MARITAL_STATUS, GENDER, RESIDENT_STATUS, HANDICAP_CLASSIFICATION } from '../../../../consts';
import {
  fullAddressFormat,
  fullNameFormat,
  dateFormat,
  amountFormat,
  optionalBooleanFormat,
  booleanFormat
} from '@jbc-year-end-adj/common/utils/formatter';
import styles from './Preview.scss';
import { Profile, Spouse } from '../../../../query';
import { useRightExpansionContainer } from 'components/feature/RightExpansionContainer';
import { Income as IncomeIcon } from 'jbc-front/components/icons';
import { Income } from './Income';

// @ts-ignore
import { salaryEarnings as calcSalaryEarnings, calcTotalSalaryIncome } from 'utils/income';

const isSameHouseholdText = (isSameHousehold: boolean | undefined) => {
  switch (isSameHousehold) {
    case true:
      return '対象';
    case false:
      return '対象外';
    default:
      return undefined;
  }
};

export const Preview: FC = () => {
  const year = useYear();
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  const thisYearIncome = (profile?: Profile) => {
    if (profile?.spouse?.thisYear?.dependentTaxLaw) {
      return profile.spouse?.income;
    } else if (Number(profile?.spouse?.income?.retirementIncome) > 0) {
      // 今年の税の扶養対象ではないが、退職手当を有する
      return profile?.spouse?.income;
    }

    return undefined;
  };

  // 配偶者の今年の収入
  const income = thisYearIncome(profile);
  const requestIncome = thisYearIncome(requestProfile);

  const { setContent } = useRightExpansionContainer();

  const key = useMemo(() => `spouseIncomeDiff-${crypto.randomUUID()}`, [income, requestIncome]);

  const handleClickIncome = () => {
    setContent(<Income income={income} requestIncome={requestIncome} year={year} />, key, 400);
  };

  const dependentTaxLawOrSpecial = (profile?: Profile) => {
    if (!profile) return false;
    if (!profile.spouse?.thisYear?.dependentTaxLaw) return false;
    if (profile.hasSpouseDeductionThisYear) return true;

    const handicapType = profile.spouse?.thisYear?.handicapType;
    return handicapType && ['normal', 'special'].includes(handicapType);
  };

  const thisYearDependentTaxLawText = (profile?: Profile) => {
    const value = year >= 2024 ? profile?.spouse?.thisYear?.dependentTaxLaw : dependentTaxLawOrSpecial(profile);
    return value ? '対象' : '対象外';
  };

  const hasRetirementEarnings = (spouse?: Spouse) => {
    if (spouse?.thisYear?.dependentTaxLaw) return false;
    if (spouse?.income?.retirementIncome === undefined) return false;

    return Number(spouse.income.retirementIncome.split(',').join('')) > 0;
  };

  const fullName = fullNameFormat(profile.spouse?.lastName, profile.spouse?.firstName);
  const fullNameKana = fullNameFormat(profile.spouse?.lastNameKana, profile.spouse?.firstNameKana);
  const requestFullName = fullNameFormat(requestProfile?.spouse?.lastName, requestProfile?.spouse?.firstName);
  const requestFullNameKana = fullNameFormat(requestProfile?.spouse?.lastNameKana, requestProfile?.spouse?.firstNameKana);

  const inputClassName = requestProfile?.maritalStatus ? undefined : styles.alert;

  return (
    <div className={styles.preview}>
      <Grid>
        <Diff
          inputClassName={inputClassName}
          title="配偶者の有無"
          source={profile.maritalStatus ? MARITAL_STATUS[profile.maritalStatus] : undefined}
          input={requestProfile?.maritalStatus ? MARITAL_STATUS[requestProfile.maritalStatus] : 'この項目は入力必須です'}
        />
      </Grid>

      {(profile.maritalStatus !== 'unmarried' || requestProfile?.maritalStatus !== 'unmarried') && (
        <PreviewSubSection>
          <PreviewSubSection.Header>配偶者の基本情報</PreviewSubSection.Header>
          <PreviewSubSection.Body>
            <Diff title="氏名" source={`${fullName} （${fullNameKana}）`} input={`${requestFullName} （${requestFullNameKana}）`} />

            <Diff
              title="性別"
              source={profile.spouse?.gender ? GENDER[profile.spouse.gender] : undefined}
              input={requestProfile?.spouse?.gender ? GENDER[requestProfile.spouse.gender] : undefined}
            />

            <Diff
              title="生年月日"
              source={dateFormat(profile.spouse?.birthday, 'L')}
              input={dateFormat(requestProfile?.spouse?.birthday, 'L')}
            />
          </PreviewSubSection.Body>
        </PreviewSubSection>
      )}

      {(profile.spouse || requestProfile?.spouse) && (
        <Grid colGap col2>
          <PreviewSubSection>
            <PreviewSubSection.Header>今年の扶養情報</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              {year === 2024 && (
                <Diff
                  title="同一生計配偶者"
                  source={isSameHouseholdText(profile.spouseLivingStatus?.isSameHousehold)}
                  input={isSameHouseholdText(requestProfile?.spouseLivingStatus?.isSameHousehold)}
                />
              )}

              <Diff
                title="今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象"
                source={thisYearDependentTaxLawText(profile)}
                input={thisYearDependentTaxLawText(requestProfile)}
              />

              <Diff
                title="今年の税の扶養対象ではないが、退職手当等を有する配偶者の対象"
                source={hasRetirementEarnings(profile.spouse) ? '対象' : '対象外'}
                input={hasRetirementEarnings(requestProfile?.spouse) ? '対象' : '対象外'}
              />

              <Diff
                title="同居・別居"
                source={profile.spouse?.thisYear?.residenceStatus ? RESIDENT_STATUS[profile.spouse?.thisYear?.residenceStatus] : undefined}
                input={
                  requestProfile?.spouse?.thisYear?.residenceStatus
                    ? RESIDENT_STATUS[requestProfile?.spouse?.thisYear?.residenceStatus]
                    : undefined
                }
              />

              {(profile.spouse?.thisYear?.residenceStatus === 'different_address' ||
                requestProfile?.spouse?.thisYear?.residenceStatus === 'different_address') && (
                <>
                  <Diff
                    title="住所"
                    source={fullAddressFormat(profile.spouse?.thisYear)}
                    input={fullAddressFormat(requestProfile?.spouse?.thisYear)}
                  />
                </>
              )}

              {(profile.spouse?.thisYear?.residenceStatus === 'different_and_foreign_address' ||
                requestProfile?.spouse?.thisYear?.residenceStatus === 'different_and_foreign_address') && (
                <>
                  <Diff
                    title="Address"
                    source={profile.spouse?.thisYear?.addressForeign}
                    input={requestProfile?.spouse?.thisYear?.addressForeign}
                  />
                  <Diff
                    title="非居住者"
                    source={booleanFormat({
                      value: profile.spouse?.thisYear?.isNonResident ?? false,
                      truthyString: '非居住者',
                      falsyString: '対象外'
                    })}
                    input={booleanFormat({
                      value: requestProfile?.spouse?.thisYear?.isNonResident ?? false,
                      truthyString: '非居住者',
                      falsyString: '対象外'
                    })}
                  />
                  <Diff
                    title="国外居住親族への送金額"
                    source={amountFormat(profile.spouse?.thisYear?.remittance)}
                    input={amountFormat(requestProfile?.spouse?.thisYear?.remittance)}
                  />
                  <FileDiff
                    title="親族関係書類"
                    source={profile.spouse?.thisYear?.relatedToRelativesDocument}
                    input={requestProfile?.spouse?.thisYear?.relatedToRelativesDocument}
                  />
                  <FileDiff
                    title="送金関係書類"
                    source={profile.spouse?.thisYear?.relatedToRemittanceDocument}
                    input={requestProfile?.spouse?.thisYear?.relatedToRemittanceDocument}
                  />
                </>
              )}
              <Diff
                title="障害者区分"
                source={
                  profile.spouse?.thisYear?.handicapType ? HANDICAP_CLASSIFICATION[profile.spouse?.thisYear?.handicapType] : undefined
                }
                input={
                  requestProfile?.spouse?.thisYear?.handicapType
                    ? HANDICAP_CLASSIFICATION[requestProfile?.spouse?.thisYear?.handicapType]
                    : undefined
                }
              />
              <Diff
                title="障害者詳細"
                source={profile.spouse?.thisYear?.handicapDetail}
                input={requestProfile?.spouse?.thisYear?.handicapDetail}
              />
              <FileDiff
                title="障害者手帳画像"
                source={profile.spouse?.thisYear?.handicapImage}
                input={requestProfile?.spouse?.thisYear?.handicapImage}
              />
              <Diff.Container>
                <Diff.Header>
                  <div className={styles.incomeDetailHeader}>
                    合計所得金額
                    <i onClick={handleClickIncome}>
                      <IncomeIcon />
                    </i>
                  </div>
                </Diff.Header>
                <Diff.Body
                  source={amountFormat(profile.spouse?.thisYear?.earnings) || '0'}
                  input={amountFormat(requestProfile?.spouse?.thisYear?.earnings) || '0'}
                />
              </Diff.Container>
            </PreviewSubSection.Body>
          </PreviewSubSection>

          <PreviewSubSection>
            <PreviewSubSection.Header>来年の扶養情報</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Diff
                title="来年の源泉控除対象"
                source={optionalBooleanFormat(profile.spouse?.nextYear?.dependentTaxLaw, '対象', '対象外')}
                input={optionalBooleanFormat(requestProfile?.spouse?.nextYear?.dependentTaxLaw, '対象', '対象外')}
              />

              <Diff
                title="同居・別居"
                source={profile.spouse?.nextYear?.residenceStatus ? RESIDENT_STATUS[profile.spouse?.nextYear?.residenceStatus] : undefined}
                input={
                  requestProfile?.spouse?.nextYear?.residenceStatus
                    ? RESIDENT_STATUS[requestProfile?.spouse?.nextYear?.residenceStatus]
                    : undefined
                }
              />

              {(profile.spouse?.nextYear?.residenceStatus === 'different_address' ||
                requestProfile?.spouse?.nextYear?.residenceStatus === 'different_address') && (
                <>
                  <Diff
                    title="住所"
                    source={fullAddressFormat(profile.spouse?.nextYear)}
                    input={fullAddressFormat(requestProfile?.spouse?.nextYear)}
                  />
                </>
              )}

              {(profile.spouse?.nextYear?.residenceStatus === 'different_and_foreign_address' ||
                requestProfile?.spouse?.nextYear?.residenceStatus === 'different_and_foreign_address') && (
                <>
                  <Diff
                    title="Address"
                    source={profile.spouse?.nextYear?.addressForeign}
                    input={requestProfile?.spouse?.nextYear?.addressForeign}
                  />
                  <Diff
                    title="非居住者"
                    source={booleanFormat({
                      value: profile.spouse?.nextYear?.isNonResident ?? false,
                      truthyString: '非居住者',
                      falsyString: '対象外'
                    })}
                    input={booleanFormat({
                      value: requestProfile?.spouse?.nextYear?.isNonResident ?? false,
                      truthyString: '非居住者',
                      falsyString: '対象外'
                    })}
                  />
                  <Diff
                    title="国外居住親族への送金額"
                    source={amountFormat(profile.spouse?.nextYear?.remittance)}
                    input={amountFormat(requestProfile?.spouse?.nextYear?.remittance)}
                  />
                  <FileDiff
                    title="親族関係書類"
                    source={profile.spouse?.nextYear?.relatedToRelativesDocument}
                    input={requestProfile?.spouse?.nextYear?.relatedToRelativesDocument}
                  />
                  <FileDiff
                    title="送金関係書類"
                    source={profile.spouse?.nextYear?.relatedToRemittanceDocument}
                    input={requestProfile?.spouse?.nextYear?.relatedToRemittanceDocument}
                  />
                </>
              )}
              <Diff
                title="障害者区分"
                source={
                  profile.spouse?.nextYear?.handicapType ? HANDICAP_CLASSIFICATION[profile.spouse?.nextYear?.handicapType] : undefined
                }
                input={
                  requestProfile?.spouse?.nextYear?.handicapType
                    ? HANDICAP_CLASSIFICATION[requestProfile?.spouse?.nextYear?.handicapType]
                    : undefined
                }
              />
              <Diff
                title="障害者詳細"
                source={profile.spouse?.nextYear?.handicapDetail}
                input={requestProfile?.spouse?.nextYear?.handicapDetail}
              />
              <FileDiff
                title="障害者手帳画像"
                source={profile.spouse?.nextYear?.handicapImage}
                input={requestProfile?.spouse?.nextYear?.handicapImage}
              />
              <Diff
                title="所得見積額"
                source={amountFormat(profile.spouse?.nextYear?.earnings) || '0'}
                input={amountFormat(requestProfile?.spouse?.nextYear?.earnings || '0')}
              />
            </PreviewSubSection.Body>
          </PreviewSubSection>
        </Grid>
      )}

      {(profile.spouse?.thisYear || profile.spouse?.nextYear) && (
        <Grid>
          <Diff
            title="源泉控除対象配偶者になった日"
            source={dateFormat(profile.spouse?.dependentFrom)}
            input={dateFormat(requestProfile?.spouse?.dependentFrom)}
          />
          <Diff
            title="源泉控除対象配偶者になった理由"
            source={profile.spouse?.dependentReason}
            input={requestProfile?.spouse?.dependentReason}
          />
          <Diff title="死亡日" source={dateFormat(profile.spouse?.diedOn)} input={dateFormat(requestProfile?.spouse?.diedOn)} />
        </Grid>
      )}
    </div>
  );
};
