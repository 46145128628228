import { yup } from '@jbc-year-end-adj/common/yup';
import { Income, Profile } from '../../../../query';
import { dateFormFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Gender, MaritalStatus, ResidenceStatus, HandicapType, FileType } from '@jbc-year-end-adj/types';

export type SpouseSchema = {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  gender: Gender;
  birthday: string;
  thisYear?: YearlyInfoSchema;
  nextYear?: YearlyInfoNextYearSchema;
  dependentFrom?: string;
  dependentReason?: string;
  diedOn?: string;
};

export type YearlyInfoSchema = {
  spouseLivingStatus?: SpouseLivingStatusSchema;
  dependentTaxLaw: 'true' | 'false';
  hasSpouseRetirementIncome?: 'true' | 'false';
  yearlyInfo: {
    residenceStatus: ResidenceStatus;
    postcode0?: string;
    postcode1?: string;
    prefectureId?: string;
    city?: string;
    street?: string;
    building?: string;
    addressForeign?: string;
    isNonResident?: boolean;
    remittance?: string;
    relatedToRelativesDocument?: File | FileType;
    relatedToRemittanceDocument?: File | FileType;
    handicapType: HandicapType;
    handicapDetail?: string;
    handicapImage?: File | FileType;
    income?: IncomeSchema;
    earnings?: string;
  };
};

export type YearlyInfoNextYearSchema = {
  dependentTaxLaw: 'true' | 'false';
  hasSpouseRetirementIncome?: 'true' | 'false';
  yearlyInfo: {
    residenceStatus: ResidenceStatus;
    postcode0?: string;
    postcode1?: string;
    prefectureId?: string;
    city?: string;
    street?: string;
    building?: string;
    addressForeign?: string;
    isNonResident?: boolean;
    remittance?: string;
    relatedToRelativesDocument?: File | FileType;
    relatedToRemittanceDocument?: File | FileType;
    handicapType: HandicapType;
    handicapDetail?: string;
    handicapImage?: File | FileType;
    income?: string;
    earnings?: string;
  };
};

export type IncomeSchema = {
  salaryIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficialPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisablity?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarings?: string;
};

export type SpouseLivingStatusSchema = {
  isSameHousehold?: 'true' | 'false';
};

export type Schema = {
  maritalStatus: MaritalStatus;
  spouse?: SpouseSchema;
  hasSpouseIncomeDetail: boolean;
};

const isDifferentAddress = (residenceStatus: ResidenceStatus) => residenceStatus === 'different_address';

const incomeSchema = yup.object({
  salaryIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .required()
    .label('給与所得 収入金額'),
  businessIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('事業所得 収入金額'),
  businessExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('事業所得 必要経費'),
  miscellaneousIncomeOfficialPension: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('収入金額（公的年金等に係る雑所得）'),
  miscellaneousIncomeOther: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('収入金額（公的年金等以外の雑所得）'),
  miscellaneousExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('雑所得 必要経費'),
  dividendIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('配当所得 収入金額'),
  dividendExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('配当所得 必要経費'),
  realEstateIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('不動産所得 収入金額'),
  realEstateExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('不動産所得 必要経費'),
  retirementIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('退職所得 収入金額'),
  lengthOfService: yup
    .string()
    .numberFormat()
    .nullable()
    .label('勤続年数'),
  retireForDisablity: yup.boolean(),
  isOfficerRetirementAllowance: yup.boolean(),
  otherIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('その他所得 収入金額'),
  otherExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('その他所得 必要経費'),
  otherExpenseSpecialDeduction: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('その他所得 必要経費 うち特別控除額'),
  otherEarings: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .nullable()
    .label('その他所得 所得金額')
});

const thisYearSchema = yup.object({
  spouseLivingStatus: yup.object({
    isSameHousehold: yup.lazy((_, { context }) => {
      if (context.year === 2024) {
        return yup.string().required('配偶者がいる場合、同一生計配偶者は「対象」「対象外」のいずれかを選択してください。');
      } else {
        return yup.string().nullable();
      }
    })
  }),
  dependentTaxLaw: yup.mixed<'true' | 'false'>(),
  hasSpouseRetirementIncome: yup.mixed<'true' | 'false'>(),
  yearlyInfo: yup
    .object()
    .when(
      ['dependentTaxLaw', 'hasSpouseRetirementIncome', 'spouseLivingStatus.isSameHousehold'],
      ([dependentTaxLaw, hasSpouseRetirementIncome, isSameHousehold], schema) => {
        if (dependentTaxLaw === 'true' || hasSpouseRetirementIncome === 'true') {
          return schema.shape({
            residenceStatus: yup
              .mixed<ResidenceStatus>()
              .required()
              .oneOf(['same_address', 'different_address', 'different_and_foreign_address'])
              .label('同居・別居'),
            postcode0: yup.string().when(['residenceStatus'], {
              is: isDifferentAddress,
              then: schema =>
                schema
                  .required()
                  .numberFormat()
                  .max(3, '郵便番号1は3桁で入力してください')
                  .label('郵便番号1'),
              otherwise: schema => schema.nullable()
            }),
            postcode1: yup.string().when(['residenceStatus'], {
              is: isDifferentAddress,
              then: schema =>
                schema
                  .required()
                  .numberFormat()
                  .max(4, '郵便番号2は4桁で入力してください')
                  .label('郵便番号2'),
              otherwise: schema => schema.nullable()
            }),
            prefectureId: yup.string().when(['residenceStatus'], {
              is: isDifferentAddress,
              then: schema => schema.required().label('都道府県'),
              otherwise: schema => schema.nullable()
            }),
            city: yup.string().when(['residenceStatus'], {
              is: isDifferentAddress,
              then: schema => schema.required().label('市区町村'),
              otherwise: schema => schema.nullable()
            }),
            street: yup.string().nullable(),
            building: yup.string().nullable(),
            addressForeign: yup.string().nullable(),
            isNonResident: yup.boolean().nullable(),
            isStudyAbroad: yup.boolean().nullable(),
            remittance: yup
              .string()
              .positiveAmountFormat()
              .maxAmount(999999999)
              .nullable()
              .label('国外居住親族への送金額'),
            relatedToRelativesDocument: yup.mixed<File>().nullable(),
            relatedToRemittanceDocument: yup.mixed<File>().nullable(),
            provingStudyAbroadDocument: yup.mixed<File>().nullable(),
            handicapType: yup
              .mixed<HandicapType>()
              .oneOf(['none', 'normal', 'special'])
              .label('障害者区分'),
            handicapDetail: yup.string().nullable(),
            handicapImage: yup.mixed<File>().nullable(),
            earnings: yup.lazy((_, { context }) => {
              if (context.year === 2024 && isSameHousehold === 'true') {
                return yup
                  .string()
                  .positiveAmountFormat()
                  .label('合計所得金額')
                  .test(
                    'sameHouseholdSpouseEarnings',
                    '同一生計配偶者の合計所得金額は480,000円以下である必要があるので、修正が必要です。',
                    value => !value || Number(value.split(',').join('')) <= 480000
                  );
              } else {
                return yup
                  .string()
                  .positiveAmountFormat()
                  .maxAmount(1330000)
                  .label('合計所得金額');
              }
            }),
            income: incomeSchema
          });
        } else if (isSameHousehold === 'true') {
          return schema.shape({ income: incomeSchema });
        } else {
          return schema.nullable();
        }
      }
    )
});

const nextYearSchema = yup.object({
  dependentTaxLaw: yup.mixed<'true' | 'false'>(),
  yearlyInfo: yup.object().when(['dependentTaxLaw'], {
    is: (dependentTaxLaw: 'true' | 'false') => dependentTaxLaw === 'true',
    then: schema =>
      schema.shape({
        residenceStatus: yup
          .mixed<ResidenceStatus>()
          .required()
          .oneOf(['same_address', 'different_address', 'different_and_foreign_address'])
          .label('同居・別居'),
        postcode0: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema =>
            schema
              .required()
              .numberFormat()
              .max(3, '郵便番号1は3桁で入力してください')
              .label('郵便番号1'),
          otherwise: schema => schema.nullable()
        }),
        postcode1: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema =>
            schema
              .required()
              .numberFormat()
              .max(4, '郵便番号2は4桁で入力してください')
              .label('郵便番号2'),
          otherwise: schema => schema.nullable()
        }),
        prefectureId: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema => schema.required().label('都道府県'),
          otherwise: schema => schema.nullable()
        }),
        city: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema => schema.required().label('市区町村'),
          otherwise: schema => schema.nullable()
        }),
        street: yup.string().nullable(),
        building: yup.string().nullable(),
        addressForeign: yup.string().nullable(),
        isNonResident: yup.boolean().nullable(),
        isStudyAbroad: yup.boolean().nullable(),
        remittance: yup
          .string()
          .positiveAmountFormat()
          .maxAmount(999999999)
          .nullable()
          .label('国外居住親族への送金額'),
        relatedToRelativesDocument: yup.mixed<File>().nullable(),
        relatedToRemittanceDocument: yup.mixed<File>().nullable(),
        provingStudyAbroadDocument: yup.mixed<File>().nullable(),
        handicapType: yup
          .mixed<HandicapType>()
          .oneOf(['none', 'normal', 'special'])
          .label('障害者区分'),
        handicapDetail: yup.string().nullable(),
        handicapImage: yup.mixed<File>().nullable(),
        income: yup.lazy((_, { context }) => {
          if (!context || context.year <= 2023) {
            return yup.string().nullable();
          } else {
            return yup
              .string()
              .positiveAmountFormat()
              .maxAmount(999999999)
              .nullable()
              .label('給与収入等');
          }
        }),
        earnings: yup
          .string()
          .positiveAmountFormat()
          .maxAmount(950000)
          .nullable()
          .label('所得見積額')
      }),
    otherwise: schema => schema.nullable()
  })
});

const spouseSchema = yup.object({
  lastName: yup
    .string()
    .required()
    .label('姓'),
  firstName: yup
    .string()
    .required()
    .label('名'),
  lastNameKana: yup
    .string()
    .required()
    .kana()
    .label('姓（カナ）'),
  firstNameKana: yup
    .string()
    .required()
    .kana()
    .label('姓（カナ）'),
  birthday: yup
    .string()
    .required()
    .dateFormat()
    .label('生年月日'),
  gender: yup
    .mixed<Gender>()
    .required()
    .oneOf(['male', 'female'])
    .label('性別'),
  thisYear: thisYearSchema,
  nextYear: nextYearSchema,
  dependentFrom: yup
    .string()
    .dateFormat()
    .nullable()
    .label('源泉控除対象配偶者になった日'),
  dependentReason: yup.string().nullable(),
  diedOn: yup
    .string()
    .dateFormat()
    .nullable()
    .label('死亡日')
});

export const schema = yup.object({
  maritalStatus: yup
    .mixed<MaritalStatus>()
    .required()
    .label('配偶者の有無'),
  spouse: yup.object().when(['maritalStatus'], {
    is: (maritalStatus: MaritalStatus) => maritalStatus !== 'unmarried',
    then: schema => schema.concat(spouseSchema),
    otherwise: schema => schema.nullable()
  }),
  hasSpouseIncomeDetail: yup.boolean()
});

export const generateDefaultValues = (profile: Profile | undefined): Schema => {
  const spouse = profile?.spouse;
  const income = spouse?.income;
  const hasSpouseRetirementIncome = spouse?.income ? checkSpouseRetirementIncome(spouse.income) : 'false';

  const hasSpouseIncomeDetail = income ? checkSpouseIncomeDetail(income) : false;

  return {
    maritalStatus: profile?.maritalStatus || 'unmarried',
    spouse: {
      lastName: spouse?.lastName || '',
      firstName: spouse?.firstName || '',
      lastNameKana: spouse?.lastNameKana || '',
      firstNameKana: spouse?.firstNameKana || '',
      gender: spouse?.gender || 'male',
      birthday: dateFormFormat(spouse?.birthday, 'L'),

      thisYear: {
        spouseLivingStatus: {
          isSameHousehold:
            profile?.spouseLivingStatus?.isSameHousehold === undefined
              ? undefined
              : profile?.spouseLivingStatus?.isSameHousehold
              ? 'true'
              : 'false'
        },
        dependentTaxLaw: spouse?.thisYear?.dependentTaxLaw ? 'true' : 'false',
        hasSpouseRetirementIncome,
        yearlyInfo: {
          residenceStatus: spouse?.thisYear?.residenceStatus || 'same_address',
          handicapType: spouse?.thisYear?.handicapType || 'none',
          handicapDetail: spouse?.thisYear?.handicapDetail,
          handicapImage: spouse?.thisYear?.handicapImage,
          postcode0: spouse?.thisYear?.postcode0,
          postcode1: spouse?.thisYear?.postcode1,
          prefectureId: spouse?.thisYear?.prefectureId,
          city: spouse?.thisYear?.city,
          street: spouse?.thisYear?.street,
          building: spouse?.thisYear?.building,
          addressForeign: spouse?.thisYear?.addressForeign,
          isNonResident: spouse?.thisYear?.isNonResident,
          remittance: amountFormat(spouse?.thisYear?.remittance),
          relatedToRelativesDocument: spouse?.thisYear?.relatedToRelativesDocument,
          relatedToRemittanceDocument: spouse?.thisYear?.relatedToRemittanceDocument,
          income: {
            salaryIncome: amountFormat(spouse?.income?.salaryIncome),
            businessIncome: amountFormat(spouse?.income?.businessIncome),
            businessExpense: amountFormat(spouse?.income?.businessExpense),
            miscellaneousIncomeOfficialPension: amountFormat(spouse?.income?.miscellaneousIncomeOfficalPension),
            miscellaneousIncomeOther: amountFormat(spouse?.income?.miscellaneousIncomeOther),
            miscellaneousExpense: amountFormat(spouse?.income?.miscellaneousExpense),
            dividendIncome: amountFormat(spouse?.income?.dividendIncome),
            dividendExpense: amountFormat(spouse?.income?.dividendExpense),
            realEstateIncome: amountFormat(spouse?.income?.realEstateIncome),
            realEstateExpense: amountFormat(spouse?.income?.realEstateExpense),
            retirementIncome: amountFormat(spouse?.income?.retirementIncome),
            lengthOfService: spouse?.income?.lengthOfService,
            retireForDisablity: spouse?.income?.retireForDisablity,
            isOfficerRetirementAllowance: spouse?.income?.isOfficerRetirementAllowance,
            otherIncome: amountFormat(spouse?.income?.otherIncome),
            otherExpense: amountFormat(spouse?.income?.otherExpense),
            otherExpenseSpecialDeduction: amountFormat(spouse?.income?.otherExpenseSpecialDeduction),
            otherEarings: amountFormat(spouse?.income?.otherEarings)
          }
        }
      },
      nextYear: {
        dependentTaxLaw: spouse?.nextYear?.dependentTaxLaw ? 'true' : 'false',
        yearlyInfo: {
          residenceStatus: spouse?.nextYear?.residenceStatus || 'same_address',
          handicapType: spouse?.nextYear?.handicapType || 'none',
          handicapDetail: spouse?.nextYear?.handicapDetail,
          handicapImage: spouse?.nextYear?.handicapImage,
          income: amountFormat(spouse?.nextYear?.income) || '0',
          earnings: amountFormat(spouse?.nextYear?.earnings) || '0',
          postcode0: spouse?.nextYear?.postcode0,
          postcode1: spouse?.nextYear?.postcode1,
          prefectureId: spouse?.nextYear?.prefectureId,
          city: spouse?.nextYear?.city,
          street: spouse?.nextYear?.street,
          building: spouse?.nextYear?.building,
          addressForeign: spouse?.nextYear?.addressForeign,
          isNonResident: spouse?.nextYear?.isNonResident,
          remittance: amountFormat(spouse?.nextYear?.remittance),
          relatedToRelativesDocument: spouse?.nextYear?.relatedToRelativesDocument,
          relatedToRemittanceDocument: spouse?.nextYear?.relatedToRemittanceDocument
        }
      },
      dependentFrom: dateFormFormat(spouse?.dependentFrom, 'L'),
      dependentReason: spouse?.dependentReason,
      diedOn: dateFormFormat(spouse?.diedOn, 'L')
    },
    hasSpouseIncomeDetail
  };
};

const checkSpouseIncomeDetail = (income: Income): boolean => {
  const {
    businessIncome,
    businessExpense,
    dividendIncome,
    dividendExpense,
    realEstateIncome,
    realEstateExpense,
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    retirementIncome,
    otherIncome,
    otherExpenseSpecialDeduction,
    otherExpense,
    otherEarings
  } = income;

  return !!(
    (businessIncome && businessIncome !== '0') ||
    (businessExpense && businessExpense !== '0') ||
    (dividendIncome && dividendIncome !== '0') ||
    (dividendExpense && dividendExpense !== '0') ||
    (realEstateIncome && realEstateIncome !== '0') ||
    (realEstateExpense && realEstateExpense !== '0') ||
    (miscellaneousIncomeOfficalPension && miscellaneousIncomeOfficalPension !== '0') ||
    (miscellaneousIncomeOther && miscellaneousIncomeOther !== '0') ||
    (miscellaneousExpense && miscellaneousExpense !== '0') ||
    (retirementIncome && retirementIncome !== '0') ||
    (otherIncome && otherIncome !== '0') ||
    (otherExpenseSpecialDeduction && otherExpenseSpecialDeduction !== '0') ||
    (otherExpense && otherExpense !== '0') ||
    (otherEarings && otherEarings !== '0')
  );
};

const checkSpouseRetirementIncome = (income: Income): 'true' | 'false' => {
  const { retirementIncome } = income;

  return retirementIncome && retirementIncome !== '0' ? 'true' : 'false';
};
